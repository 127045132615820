@media print {
    .container {
        max-width: 100%;
    }

    .row {
        display: block;
    }

    td.table-success {
        background-color: transparent !important;
        color: var(--bs-success) !important;
    }

    td.table-danger {
        background-color: transparent !important;
        color: var(--bs-danger) !important;
    }

    .table>tbody>*>td {
        padding: 0 0.5rem;
    }
}